html {
  height: 100%;
  margin: 0;
  padding: 0;
}
body {
  height: 100%;
  margin: 0;
  font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  font-size: .9rem;
}
a:link {
  color: #0C58CA;
  text-decoration:none;
}
a:hover {
  /* color: #344777; */
  text-decoration: underline;
}
/* a:active{
  color: #ACCEF7;
} */
/* a:visited {
  color: green;
}
a:active {
  color: hotpink;
}  */
/* body {
  display: flex;
  flex-wrap: nowrap;
  height: 100vh;
  height: -webkit-fill-available;
  overflow-x: auto;
  overflow-y: hidden;
}
body > * {
  flex-shrink: 0;
  min-height: -webkit-fill-available;
} */

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

 /*
 * Color
 */

  /* AmX
  #006FCF rgb(0,111,207) light
  #0061B6 rgb(0,97,182) dark pushed buttons_1
  #2D67A0 rgb(45,103,160) darker pushed buttons_2
  #001956 rgb(0,25,86) darkest pushed buttons_3 */

  /* bootstrap primary color is #0d6efd */
  /* #bootstrap primary=#0D6EFD; rbg(13,110,253); hover-dark-color=rbg(13,98,225); */
  .text-primary {color: #126BC5 !important;} /* rgb(18,107,197)  mixed-made=#1260B6 */ 
  .text-dblue {color: #0A4386 !important;} /* rgb(10,67,134) */
  
  .blue-light {color: #4c92e2;}
  .blue-lighter {color: #86b5ea;}

  .red-mid {color: #F86579;} /* rgb(?,?,?) */
  .red-dark {color: #b52033;} /* rgb(?,?,?) */
  
  .green-lighter {color: #C7EFD0;}  /* #C7EFD0 */ 
  .green-light {color: rgb(78,163,103);}  /* #4EA367  */ 
  .green-mid {color: rgb(18,136,66);}  /* #128842 */ 
  .green-dark {color: rgb(62,133,73);}  /* #3E8549 */ 

  .grey-darker {color: #353535;}   /* hsl(0, 0%, 21%) */
  .grey-dark {color: #494949;}   /* hsl(0, 0%, 29%) */
  .grey {color: #7a7a7a;}   /* hsl(0, 0%, 48%) */
  .grey-light {color: #b5b5b5;}   /* hsl(0, 0%, 71%) */
  .grey-lighter {color: #dbdbdb;}   /* hsl(0, 0%, 86%) */
  .grey-lightest {color: #ededed;}   /* hsl(0, 0%, 93%) */

  .orange {color: #ffd256 !important; }
  .dark-orange {color: #F1C652 !important; }
  .darker-orange {color: #ed710c !important}

  .agg-orange {color: #ef7714 !important}

 /*
 * Background Color
 */


  .bg-orange {
    /* background: #FEAA44 !important; */
    /* background: #FFC008 !important; */
    /* background: #ffc108cc !important; */
    background: #ffd256 !important;
    /* background: #F1C652 !important; */
    /* background: #ffc108b0 !important; */
    /* background: #FD8440 !important; */
    /* background: #fddb27 !important; */
    /* background: rgb(200, 255, 0) !important; */
    /* background: #b6ff00 !important; */
    /* background: rgb(78,163,103) !important; */
    /* background: rgb(18,136,66) !important; */
    /* background: rgb(62,133,73) !important; */
    color: black;
    /* color: white; */
  }
  .bg-green {
    background: #C7EFD0;
    color: black;
  }

  .bg-pink {
    background: #FEC7CE;
    color: black;
  }
  .bg-red {
    background: #FC8696;
    color: black;
  }

  .row-bg-purple {
    background: #6b5b95;
    color: white;
  }

  .row-bg-dark-green {
    background: #405d27;
    color: white;
  }

  .row-bg-dark-blue {
    background: #034f84;
    color: white;
  }

  .row-bg-dark-gray {
    background: #494949;
    color: white;
  }


  .blue {background: #afd9ee !important;}              /* active row in the variant table */

  .bg-dark-blue {background-color: #203556;}           /* navbar */
  .bg-mid-blue {background-color: #344777; }           /* not used yet, but was for a:hover */
  .bg-blue {background-color: #225FB3;}                /* resizer bg */
  .bg-lighter-blue {background-color: #486EB7;}        /* unselected tabs */
  .bg-lightsteel-blue {background-color: #B0C4DE;}     /* selected item bg lightsteelblue=#B0C4DE */
  .bg-info-blue {background-color: #b9def0; }          /* selected row in tables */   /* bg of topic title */
  .bg-light-blue {background-color: #e3eaf3;}          /* Variant section title bg */
   
  .bg-grey-darker {background-color: #353535;}   /* hsl(0, 0%, 21%) */
  .bg-grey-dark {background-color: #494949;}   /* hsl(0, 0%, 29%) */
  .bg-grey {background-color: #7a7a7a;}   /* hsl(0, 0%, 48%) */
  .bg-grey-light {background-color: #b5b5b5;}   /* hsl(0, 0%, 71%) */
  .bg-grey-lighter {background-color: #dbdbdb;}   /* hsl(0, 0%, 86%) */
  .bg-grey-lightest {background-color: #ededed;}   /* hsl(0, 0%, 93%) */
  
  /* .border-red {border: 2px solid #f51b0b; padding: .1rem;} */
  /* .border-light-blue {border: 2px solid #e3eaf3; padding: .1rem;} */
  .border-red {border: 2px solid #f33c1c; padding: .1rem;}
  .border-light-blue {border: 2px solid #e3eaf3; padding: .1rem;}

  .bg-light-pink {background-color: #fde2e6;}
  /* .bg-light-pink {background-color: #fcd9df;} */

  .bg-white {background-color: #ffffff;}    /* hsl(0, 0%, 98%) */
  .bg-white-bis {background-color: #f9f9f9;}    /* hsl(0, 0%, 98%) */

  /*
  * Not used yet!!
  */
  
  .bg-selected-item-active{background-color:#6dbcc9;}  /*  active selected item bg */
  
  /* colors*/
  /* dark-yellow  */
  /* .mid-yellow {background-color:#fddb27;} */
  /* .yellow-green-phosphoric {background-color:#b6ff00;} */

  /* .black {background-color: #0a0a0a;} */    /* hsl(0, 0%, 4%) */
  /* .black-bis {background-color: #111111;} */    /* hsl(0, 0%, 7%) */
  /* .black-ter {background-color: #232323;} */    /* hsl(0, 0%, 14%) */

  /* .bg-white-ter {background-color: #f4f4f4;} */    /* hsl(0, 0%, 96%) */

 /*
 * Calc
 */

  .main-content {
    position: relative;
    /* height: calc(100vh - 4.1rem); */
    height: calc(100vh - 4.5rem) !important;
    height: -o-calc(100vh - 4.5rem) !important; /* opera */
    height: -webkit-calc(100vh - 4.5rem) !important; /* google, safari */
    height: -moz-calc(100vh - 4.5rem) !important; /* firefox */
  }

  .main-variant-viewer-content {
    position: relative;
    /* height: calc(100vh - 4.1rem); */
    height: calc(100vh - 5rem) !important;
    height: -o-calc(100vh - 5rem) !important; /* opera */
    height: -webkit-calc(100vh - 5rem) !important; /* google, safari */
    height: -moz-calc(100vh - 5rem) !important; /* firefox */
  }

  .variant-viewer-split-pane {
    height: 100%;  
    /* ! maybe remove height? */
    display: flex;
    position: relative;
    flex-grow: 1;
  }
  
  /*!  remove later , if not used in XlsxViewer and change it CnvViewer */
  .sub-split-pane-height {
    height: calc(100vh - 7.5rem) !important;
    height: -o-calc(100vh - 7.5rem) !important; /* opera */
    height: -webkit-calc(100vh - 7.5rem) !important; /* google, safari */
    height: -moz-calc(100vh - 7.5rem) !important; /* firefox */
  }



 /*
 * General
 */

  .fw-semibold { font-weight: 600; }

  .lh-tight { line-height: 1.25; }

  /* .b-horizontal-divider {
    background-color: rgba(0, 0, 0, .1);
    border: solid rgba(0, 0, 0, .15);
    border-width: 1px 0;
    box-shadow: inset 0 .5em 1.5em rgba(0, 0, 0, .1), inset 0 .125em .5em rgba(0, 0, 0, .15);
  }  */

  .show-overflow {
    overflow: auto;
  }

  .cursor-default {
    cursor:default;
  }

  .cursor-pointer {
    cursor: pointer;
  }

  .height-2rem {
    height: 2rem;
  }

  .no-white-space-wrap {
    white-space: nowrap;
  }

  .display-linebreak {
    white-space: pre-line;
  }

  .text-small{
    font-size: small;
    /* font-size: x-small; */
  }

 /*
 * General, mostly used in variant viewer
 */

  .limited-text-height {
    max-height: 10vh;
    overflow: auto;
  }

  .flex-center {
    align-items: center;
    justify-content: center;
    display: flex;
  }

  .sticky-header {
    display: flex;
    position: sticky; 
    top: 0;
    z-index: 1;
  }
  
  .plus-border-radius {
    border-radius: 1rem;
  }

  /* .cutextra {
    overflow:hidden !important;
    white-space:nowrap;
    text-overflow:ellipsis;
  } */

 /*
 * initial-view
 */

  .initial-view-wrapper {
    /* background-image: linear-gradient(180deg, #fff, hsl(0, 0%, 20%)); */
    /* background-image: linear-gradient(180deg, #ddd, #fff 100px, #fff); */
    /* background-image: linear-gradient(180deg, hsl(0, 0%, 81%), hsl(0, 0%, 97%) 100px, #fff 100px, #fff); */
    background-color: #f9f9f9;
    height: 100vh;
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .initial-view-width-lim {
    /* max-width: 640px; for 2 columns */
    /* max-width: 960px; 960px for 3 columns*/
    /* max-width: 1280px; /*1280 for 4 columns*/
    /* max-width: 1280px; */
    max-width: 1750px;
    /* width: 100% !important; */
    /* max-width: 100% !important; */
  }

  .initial-view-bottom-box {
    max-width: 960px; 
  }

  .initial-view-version {
    font-size: small;
    vertical-align: super;
  }

  .project-view-version {
    position: absolute;
    top:0;
    font-size:xx-small;
    vertical-align: super;
    color: white;
  }

  .list-unstyled > li {
    margin: 0.2rem;
  }

  .heart{
    color:firebrick;
  }

  .vanex-logo {
    /* height: 40vmin; */
    height: 3rem;
    pointer-events: none;
    margin: .4rem .2rem;
    /* margin: 0rem .2rem 1rem .2rem; */
  }

  .drag-drop-div {
    /* background-color:#e3eaf3; */
    /* min-height:18rem; */
    border:3px dashed gray;
    margin: .1rem;
  }

  .card-with-min-height {
    min-height: 17.5rem;
    /* height: 18rem; */
  }

  .file-choose{
    /* border: none; */
    padding: 0rem;
    margin: 0rem;
  }

  .file-choose::-webkit-file-upload-button {
    /* display: inline-block; */
    /* border-radius: 5px; */
    /* border: none; */
    /* background: #1CB6E0; */
    background-color: #198753;
    color: white;
    padding: .5rem 8.3rem;
    margin: 0rem 20rem 0rem 0rem;
    white-space: nowrap;
    cursor: pointer;
    font-size: 120%;
    /* font-weight: 700; */
  }

  .file-choose:hover::-webkit-file-upload-button {
    /* color: black; */
    border: none;
    background: #1c9b60 !important;
  }

  
 /*
 * Footer
 */

  .rd-footer {
    height: 1.5rem;
    border: 1px solid #ccc;
  }


 /*
 * Navbar
 */

  .navbar {
    height: 3rem;
    /* background-color:#203556 ; /*   #e3f2fd  #d2f4ea;  mediumaquamarine mediumseagreen; */
  }
  
  .navbar-brand {
    cursor: pointer;
    /* padding-top: .5rem;
    padding-bottom: .5rem;
    font-size: 1rem; */
    /* background-color: rgba(0, 0, 0, .25); */
    /* box-shadow: inset -1px 0 0 rgba(0, 0, 0, .25); */
  }

  /* .navbar .navbar-toggler {
    top: .25rem;
    right: 1rem;
  }

  .navbar .form-control {
    padding: .75rem 1rem;
    border-width: 0;
    border-radius: 0;
  }

  .form-control-dark {
    color: #f9f9f9;
    background-color: rgba(255, 255, 255, .1);
    border-color: rgba(255, 255, 255, .1);
  }

  .form-control-dark:focus {
    border-color: transparent;
    box-shadow: 0 0 0 3px rgba(255, 255, 255, .25);
  } */
  
 /*
 * search-bar in Navbar
 */

  .search-type-btn {
    /* background-color: #41d7fd; */
    /* background-color: #5fdbfb; */
    min-width: 10rem;
    border-radius: .8rem;
    height: 1.8rem;
  }

  .dropdown-menu {
    border-radius: 1rem;
    margin-left: .4rem;
    margin-top: -1rem;
  }

  .dropdown-item:hover {
    background-color:#B0C4DE;
    color: #000;
  }
  
  .search-bar-form {
    /* max-width: 800px; */
    /* width: 80%; */
    width: 100%;
  }
  
  .search-bar {
    max-width: 500px;
    /* width: 80%; */
    /* width: 30vw; */
    height: 2rem;
    border-radius: .8rem;
  }

  .search-bar:focus {
    outline: none !important;
    border:2px solid #225FB3;
    box-shadow: 0 0 0.3rem #e3eaf3;
  }

  .vanex-navbar-logo {
    /* height: 40vmin; */
    height: 1.4rem;
    pointer-events: none;
    margin: 0rem 0rem .3rem 0rem;
  }

 /*
 * Alert panel
 */

  .alert-panel-wrapper{
    position: absolute;
    width: 100%;
    z-index: 100;
  }

  .fadeOut {
    animation: alert-fade-out-anim 2s ease 3s forwards;
  }

  @keyframes alert-fade-out-anim {
    to {
      opacity: 0;
      transition: opacity 0.5s;
      -webkit-transition: opacity 0.5s;
    }
  }

 /*
 * Sidebar toggle new!
 */

  .btn-toggle {
    display: inline-flex;
    align-items: center;
    padding: .25rem .5rem;
    font-weight: 600;
    color: rgba(0, 0, 0, .65);
    background-color: transparent;
    border: 0;
  }
  .btn-toggle:hover,
  .btn-toggle:focus {
    color: rgba(0, 0, 0, .85);
    /* border: 1px solid lightsteelblue; */
    /* background-color: lightsteelblue; */
    /* background-color: #d2f4ea; */
  }

  .btn-toggle::before {
    width: 1.25em;
    line-height: 0;
    content: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='rgba%280,0,0,.5%29' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M5 14l6-6-6-6'/%3e%3c/svg%3e");
    transition: transform .35s ease;
    transform-origin: .5em 50%;
  }

  .btn-toggle[aria-expanded="true"] {
    color: rgba(0, 0, 0, .85);
  }
  .btn-toggle[aria-expanded="true"]::before {
    transform: rotate(90deg);
  }

  /* .btn-toggle-nav a {
    display: inline-flex;
    padding: .1875rem .5rem;
    margin-top: .125rem;
    margin-left: 1.25rem;
    text-decoration: none;
  }
  .btn-toggle-nav a:hover,
  .btn-toggle-nav a:focus {
    background-color: #d2f4ea;
  } */

  .case-badge {
    /* display: none; */
    visibility: hidden;
  }

  .cases-list > li {
    cursor:pointer;
  }

  .cases-list > li:hover {
    background-color: #B0C4DE;
    border-radius: 16px;
  }

  /* .cases-list > li:hover .case-badge {
    visibility:visible;
  } */

  .loaded-case.case-badge {
    visibility:visible;
  }

 
  .active-case {
    background-color:#B0C4DE;
    font-weight: bold;
    border-radius: 16px;
  }

  /*! replace with  show-overflow */
  .scroll-div {
    /* height: calc(100vh - 7rem); */
    overflow: auto;
    margin-right: .2rem;
  }




 /*
 * SplitPane
 */

  .Resizer {
    background: #000;
    opacity: 0.2;
    z-index: 1;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -moz-background-clip: padding;
    -webkit-background-clip: padding;
    background-clip: padding-box;
  }

  .Resizer:hover {
    -webkit-transition: all 2s ease;
    transition: all 2s ease;
  }

  .Resizer.horizontal {
    height: 12px;
    margin: -5px 0;
    border-top: 5px solid rgba(255, 255, 255, 0);
    border-bottom: 5px solid rgba(255, 255, 255, 0);
    cursor: row-resize;
    width: 100%;
  }

  .Resizer.horizontal:hover {
    border-top: 5px solid rgba(0, 0, 0, 0.5);
    border-bottom: 5px solid rgba(0, 0, 0, 0.5);
  }

  .Resizer.vertical {
    width: 12px;
    margin: 0 -5px;
    border-left: 5px solid rgba(255, 255, 255, 0);
    border-right: 5px solid rgba(255, 255, 255, 0);
    cursor: col-resize;
  }

  .Resizer.vertical:hover {
    border-left: 5px solid rgba(0, 0, 0, 0.5);
    border-right: 5px solid rgba(0, 0, 0, 0.5);
  }
  .Resizer.disabled {
    cursor: not-allowed;
  }
  .Resizer.disabled:hover {
    border-color: transparent;
  }



/*
 * Scrollbar
 */
  /* width */
  ::-webkit-scrollbar {
    height: .6rem;
    width: .6rem;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    width: 20px;
    background: #f1f1f1;
    /* -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);  */
    /* background-color: transparent; */
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    /* background: #888;  */
    border-radius: 10px;
    box-shadow: inset 0 0 6px rgba(0,0,0,0.5);
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5); 
    background-color: #d6dee1;
    /* background: #555; */
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    /* height: 10px; */
    background: #555;
  }



 /*
 * React Tabs (with top tabs)
 */


  .react-tabs {
    -webkit-tap-highlight-color: transparent;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  /* width */
  .react-tabs__tab-list::-webkit-scrollbar {
    height: 5px;
    /* width: 5px; */
  }

  /* Track */
  .react-tabs__tab-list::-webkit-scrollbar-track {
    background: #f1f1f1;
    /* -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);  */
    /* background-color: transparent; */
  }

  /* Handle */
  .react-tabs__tab-list::-webkit-scrollbar-thumb {
    /* background: #888;  */
    border-radius: 10px;
    box-shadow: inset 0 0 6px rgba(0,0,0,0.5); 
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5); 
    background-color: #d6dee1;
    /* background: #555; */
  }

  /* Handle on hover */
  .react-tabs__tab-list::-webkit-scrollbar-thumb:hover {
    /* height: 10px; */
    background: #555;
  }

  .react-tabs__tab-list {
    /* background-color:rgb(221, 221, 221) ; */
    /* background-color:rgb(240, 240, 240) ; */
    background-color:#344777;
    /* background-color:#dcf3ff; */
    /* border-bottom: 2px solid #ddd; */
    border-bottom: 1px solid #f9f9f9;
    margin: 0;
    padding: .3rem .5rem 0rem;
    display: flex;
    flex-wrap: nowrap; 
    overflow: auto;
    min-height: 2.2rem !important;
  }

  .react-tabs__tab {
    display: flex;
    position: relative;
    list-style: none;
    cursor: pointer;
    font-weight: bold;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: center;
    z-index: 1;
    padding: .4rem .8rem .1rem ;
    /* padding: .4rem 1rem .1rem ; */
    margin: 0rem .1rem;
    /* overflow: hidden; */

    /* color: rgb(125, 125, 125); */
    /* color: rgb(100, 100, 100); */
    /* color: rgb(240, 240, 240); */
    /* color: #f9f9f9; */
    color: hsl(0, 0%, 93%);
    /* color: #0052cc; */
    /* color: #aaa; */

    /* border: 2px solid transparent; */
    /* border-bottom: 2px solid #0052cc; */
    /* border-left: 1px solid hsl(0, 0%, 48%); */
    /* background: #fefefe; */
  }

  .react-tabs__tab::before,
  .react-tabs__tab::after,
  .react-tabs__tab--selected::before,
  .react-tabs__tab--selected::after {
    content: '';
    /* display: block; */
    position: absolute;
  }

  .react-tabs__tab::before {
    top: 0; left: 0;
    width: 70%;
    height: 100%;
    border-style: solid;
    border-color: #eee;
    border-width: 2px 0 0 2px;
    border-radius: 8px 0 0 0;
    -webkit-transform: skewX(-20deg);
       -moz-transform: skewX(-20deg);
         -o-transform: skewX(-20deg);
            transform: skewX(-20deg);
    background-color: #344777;
    z-index: -1;
  }

  .react-tabs__tab::after {
    top: 0; right: 0;
    width: 70%;
    height: 100%;
    border-style: solid;
    border-color: #eee;
    border-width: 2px 2px 0 0;
    border-radius: 0 8px 0 0;
    -webkit-transform: skewX(20deg);
       -moz-transform: skewX(20deg);
         -o-transform: skewX(20deg);
            transform: skewX(20deg);
    background-color: #344777;
    z-index: -1;
  }

  .react-tabs__tab:hover {
    color: #0052cc;
    z-index: 10;
  }

  .react-tabs__tab:hover::before {
    background-color: #f9f9f9;
    border-bottom: 2px solid #f9f9f9;   /* ? not sure hwy, but need this! */
  }
  .react-tabs__tab:hover::after {
    background-color: #f9f9f9;
    border-bottom: 2px solid #f9f9f9;   /* ? not sure hwy, but need this! */
  }

  .react-tabs__tab--selected {
    /* border-radius: 5px 5px 0 0; */
    /* content: none; */
    /* box-shadow: 0 0 5px #0052cc; */
    /* box-shadow: 0 0 5px hsl(208, 99%, 50%); */
    /* clip-path: inset(-5px -5px 0px -5px); */       /* ? Remove one side of box shadow */
    /* border: 1px solid hsl(208, 99%, 50%); */
    /* border-bottom: 1px solid transparent; */
    /* border-radius: 10px 10px 0px 0; */
    /* border-bottom-right-radius: 10px; */
    color: #0052cc;
    z-index: 10;
  }

  .react-tabs__tab--selected::before {
    background-color: #f9f9f9;
    border-bottom: 2px solid #f9f9f9;   /* ? not sure hwy, but need this! */
  }
  
  .react-tabs__tab--selected::after {
    background-color: #f9f9f9;
    border-bottom: 2px solid #f9f9f9;   /* ? not sure hwy, but need this! */
  }

  .react-tabs__tab--disabled {
    color: gray;
    cursor: default;
  }

  /* .react-tabs__tab:focus {
    box-shadow: 0 0 5px hsl(208, 99%, 50%);
    border-color: hsl(208, 99%, 50%);
    outline: none;
  }

  .react-tabs__tab:focus::after {
    content: "";
    position: absolute;
    height: 5px;
    left: -4px;
    right: -4px;
    bottom: -5px;
    background: #f9f9f9;
  } */

  .react-tabs__tab-panel {
    display: none;
  }

  .react-tabs__tab-panel--selected {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  /*
 * React Tabs (with bottom tabs) 
 */

  .bottom-tabs__tab-list{
    background-color:#dcf3ff;
    margin: 0;
    padding: 0 .5rem .2rem;
    display: flex;
    flex-wrap: nowrap; 
    overflow: auto;
    position: fixed;
    bottom: 0;
    width: 100%;
    /* height: 2rem; */
    border-top: 2px solid #344777;
  }

  .bottom-tabs {
    display: flex;
    position: relative;
    list-style: none;
    cursor: pointer;
    font-weight: bold;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: center;
    z-index: 1;
    padding: .1rem 1rem ;
    margin: 0rem .1rem;
    /* overflow: hidden; */
    color: #494949;
  }

  .bottom-tabs::before,
  .bottom-tabs::after {
    content: '';
    /* display: block; */
    position: absolute;
  }

  .bottom-tabs::before {
    top: 0; left: 0;
    width: 70%;
    height: 100%;
    border-style: solid;
    border-color: #7a7a7a;
    border-width: 0 0 2px 2px;
    border-radius: 0 0 0 8px;
    -webkit-transform: skewX(20deg);
      -moz-transform: skewX(20deg);
        -o-transform: skewX(20deg);
            transform: skewX(20deg);
    background-color: #dbdbdb;
    z-index: -1;
  }

  .bottom-tabs::after {
    top: 0; right: 0;
    width: 70%;
    height: 100%;
    border-style: solid;
    border-color: #7a7a7a;
    border-width: 0 2px 2px 0;
    border-radius: 0 0 8px 0;
    -webkit-transform: skewX(-20deg);
      -moz-transform: skewX(-20deg);
        -o-transform: skewX(-20deg);
            transform: skewX(-20deg);
    background-color: #dbdbdb;
    z-index: -1;
  }

  .bottom-tabs:hover {
    color: #f9f9f9;
    z-index: 10;
  }

  .bottom-tabs:hover::before {
    background-color: #344777;
    border-color: #344777;
  }
  .bottom-tabs:hover::after {
    background-color: #344777;
    border-color: #344777;
  }

  .bottom-tabs--selected {
    /* border-radius: 5px 5px 0 0; */
    /* content: none; */
    /* box-shadow: 0 0 5px #0052cc; */
    /* box-shadow: 0 0 5px hsl(208, 99%, 50%); */
    /* clip-path: inset(-5px -5px 0px -5px); */       /* ? Remove one side of box shadow */
    /* border: 1px solid hsl(208, 99%, 50%); */
    /* border-bottom: 1px solid transparent; */
    /* border-radius: 10px 10px 0px 0; */
    /* border-bottom-right-radius: 10px; */
    color: #f9f9f9;
    z-index: 10;
  }

  .bottom-tabs--selected::before {
    background-color: #344777;
    border-color: #344777;
  }

  .bottom-tabs--selected::after {
    background-color: #344777;
    border-color: #344777;
  }

  .bottom-tab-panel {
    background-color: #f9f9f9;
    border-radius: 0 0 .8rem .8rem;
    margin: 0 .4rem;
    flex-grow: 1;
  }

  .search-result-variant-panel {
    display: flex;
    flex-direction: column;
    height: 100%;
    flex-grow: 1;
    position: relative;
    padding: 0rem;
    margin: 0rem;
  }

  .search-result-variant-tab {
    background-color: transparent;
    margin: 0;
    padding: 0;
    text-align: center;
    width: 100%;
    list-style: none;
  }


  .extra-margin {
    margin: 0rem 0rem 2rem;
    /* margin: 2rem 2rem 4rem; */
  }

  .tab-menu-button-group {
    position: absolute;
    right: 0;
    top: 0;
    margin: 0;
    padding: 0;
    z-index: 2;
    /* border-left: #f9f9f9 2px solid ; */
    color: #f9f9f9;
    background-color: #344777;
    /* height: 2rem; */
  }
  .tab-menu-button-group::before {
    content: '';
    position: absolute;
    left: 0;
    top: 30%;
    height: 50%;
    border-left: #f9f9f9 2px solid ;
  }

  /* .tab-menu-button-group > span {
    cursor: pointer;
  } */

  /* .tab-menu-button-group > i {
    border-radius: 100%;
    width: 1.8rem;
    height: 1.8rem;
    margin: 0.1rem 0 0;
    padding: .2rem .3rem 0rem; */
    /* background-color: #e3eaf3; */
    /* color: #203556; */
  /* } */

  /* .tab-menu-button-group:hover > i {
    background-color: #e3eaf3;
    color: #203556; */
    /* background-color: #344777; */
    /* color: #e3eaf3; */
  /* } */

  .export-progressbar {
    height: 3px;
    margin: -4px 0 0 0;
    padding: 0;
  }

  .tab-menu-button {
    background-color:#344777;
    color: #f9f9f9;
  }

  .tab-menu-button:hover {
    background-color:#f9f9f9;
    color: #344777;
    /* background-color:#486EB7; */
    border-radius: 10px;
  }

  .tab-menu-button:focus {
    background-color:#f9f9f9;
    color: #344777;
    /* background-color:#486EB7; */
    border-radius: 10px;
  }

  .tab-menu-button.dropdown-toggle {
    border-radius: 5px !important;
  }

  .export-menu {
    border: 1px solid #344777;
  }


/*
* ----------------------- Panels (Side, Main, Top, Bottom)  -----------------------
*/

  .sidebar-splitpane-panel {
    /* background-color:#e3f2fd; */
    /* padding: 6px 14px 6px 14px; */
    background-color:#f9f9f9;
    flex-direction: column;
    display: flex;
    height: 100%;
  }
  
  .VariantSplitPaneWrapper {
    display: flex;
    position: relative;
    margin: 2px;
  }

  .bottom-var-info-panel {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    /* flex-wrap: wrap; */
  }

  .inner-tabs-wrapper{
    display: flex;
    /* flex: 1 1 0%; */
    height: 95%;
    /* width: 100%; */
  }

  .chart-div {
    position: relative;
    font-size: 16px !important;
    line-height: 24px !important;
    font-weight: 400 !important;
    /* width: 100%; */
    /* height: 100%; */
  }

  .svg-element {
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 3;
  }

  /* .spx{
    display: flex;
    flex: 1 1 0%;
    height: 100%;
    position: absolute;
    outline: none;
    overflow: hidden;
    user-select: text;
    bottom: 0px;
    flex-direction: column;
    min-height: 100%;
    top: 0px;
    width: 100%;
  }
  .unpane{
    flex: 0 0 auto;
    position: relative;
    outline: none;
    height: 119px;
    display: flex;
  } */

/*
* ----------------------- variant info section  -----------------------
*/

  .variant-card-header {
    padding: .3rem .8rem;
    margin: 0;
  }

  .variant-card-body {
    padding: .5rem;
    /* min-width: min-content; */
  }

  .variant-item {
    min-width: 7rem;
    padding: 0rem .6rem;
    flex: 1 1;
  }

  .variant-item-HGVS {
    flex: 3 3 im !important;
  }

  .variant-full-name-display {
    white-space: nowrap;
    overflow: auto;
    max-width: 50%;
  }

  .variant-info-box {
    /* max-height: 80vh; */
    height:auto;
    /* transition-delay: 0s; */
    /* transition: max-height .5s ease-out; */
  }

  .variant-info-box.collapsed {
    max-height: 0px;
    padding: 0px !important;
    overflow: hidden;
    /* transition-delay: 0s; */
    /* transition: max-height 1s linear; */
  }

  .vanex-review-cell {
    /* background-color: #006000; */
    /* position: absolute; */
    /* position: relative; */
    /* display: flex; */
    /* flex-direction: row; */
    overflow: hidden !important;
  }

  .clinvarplot  {
    max-width: 80%;
    /* z-index:1; */
    margin:auto;
    /* padding:4px; */
    border:1px solid #006000;
    background-color:#f9f9f9;
    box-shadow:5px 5px 5px #666;
    outline:0;
    /* cursor:pointer; */
  }

  /* .clinvarplot:focus {  
    max-width:fit-content;
    cursor:auto;
  } */


  .firefly-gene-name-in-cnv {  
    cursor: pointer;
    background-color: transparent;
  }

  .firefly-gene-name-in-cnv:hover {  
    background-color: #b9def0;  /* bg-info-blue  */
  }

  .cnvPlot {
    width: 100%;
    margin:auto;
    border:1px solid #006000;
    background-color:#f9f9f9;
    box-shadow:5px 5px 5px #666;
    outline:0;
    cursor:pointer;
  }

  .cnvPlot:focus {  
    width:auto;
    cursor:auto;
  }

  .clinical-synopsis {
    background-color: #ffffff;
    /* border: 2px solid #344777; */
  }

  .clinical-synopsis td {
    margin: 0;
    padding: 0.3rem;
    border: 1px solid #ededed;
    /* border-bottom: 1px solid #ededed; */
    /* border-right: 1px solid #ededed; */
    text-align: left;
    vertical-align: top;
    min-width: 15rem;
  }

  .clinical-synopsis-overlay {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
  }

  .opaque {
    /* background-color: #ffffff; */
    background:linear-gradient( transparent 1px, #ffffff);
  }

  /* .xx:before {
    content:'';
    width:100%;
    height:100%;    
    position:absolute;
    left:0;
    top:0;
    background-color: rgba(255,255,255,0.7);
    background:linear-gradient(transparent 150px, white);
  } */

  .functional-element-annotation-text{
    margin: .5rem;
  }

  .minim {
    min-width: 3em;
  }

/*
* ----------------------- variant table  -----------------------
*/
  
  .absolute-panel {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
 
  .variant-table th,
  .variant-table td {
    margin: 0;
    padding: 0 0 0 0.3rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-bottom: 1px solid #ededed;
    border-right: 1px solid #ededed;
    /* white-space: normal !important; */
    /* position: relative; */
  }

  .change-sample-type-table th,
  .change-sample-type-table td {
    margin: 0;
    padding: 0.2rem 0.4rem;
    text-align: center;
  }
  .change-sample-type-table td {
    border-top: 1px solid #ededed;
  }

  .variant-table td {
    overflow: auto;
    white-space: nowrap;
    /* white-space: normal !important; */
    /* word-wrap: break-word; */
  }

  .variant-table th {
    white-space: nowrap;
    background-color: #e3eaf3;
    overflow: hidden;
    /* background:#eee; */
    /* color: #f9f9f9; */
    /* border-top: 1px solid #aaa; */
    /* border-bottom: 1px solid #aaa; */
    /* background-color: #344777; */
  }

  .variant-table tr {
    min-height: 2.3rem;
  }

  .variant-table tr:nth-child(even) {
    background-color: #fafafa;
  }
  
  .variant-table tr:hover {
    /* background-color:#b9def0 !important; */
    /* background-color:#d7f2ff !important; */
    background-color:#dcf2fd !important;   
    /* background-color:#e3f6ff !important; */
    cursor: pointer;
  }

  .filter-icon {
    /* color: #7a7a7a; */
    /* color: #b5b5b5; */
    /* color: #dbdbdb; */
    /* color: #fc8696; */
    /* color: #f86579; */
    /* color: #0a0a0a; */
    color: #225FB3;
    /* color: rgb(252, 90, 90); */
    display: none;
  }

  .variant-table th:hover .filter-icon {
    /* color: #0a0a0a; */
    display: block;
    position: absolute;
    right: 0;
    top: 0;
    padding: 3px 5px 0 0;
  }

  .filter-icon:hover {
    color: #0a0a0a;
    /* color: #f86579; */
    /* color: #fc1e3bd0; */
  }

  .filter-description {
    overflow:hidden;
    white-space:nowrap;
    text-overflow:ellipsis;
    height: 1.5rem;
  }

  .filter-description:hover {
    text-overflow: clip;
    white-space: normal;
    word-break: break-all;
    overflow-y: auto;
  }

  /* .filter-description:hover::before {
    content: attr(data-title);
    position: absolute;
    bottom: -46px;
    padding: 10px;
    background: #000;
    color: #fff;
    font-size: 14px;
    white-space: nowrap;
    z-index: 20;
  } */

  .add-new-filter-wrapper {
    position: relative;
  }

  .add-new-filter {
    position: relative;
    /* border-color: rgba(0,0,0,0.2); */
    /* background-color: #ee6e73; */
    /* background-color: #789adb; */
    /* background-color: #6d90cf; */
    background-color: #225FB3;
    opacity: 0.6;
    color: #f9f9f9;
    width: 40px;
    height: 40px;
    border-radius: 100%;
    /* z-index: 2; */
    cursor: pointer;
    /* bottom: 2.5rem; */
    /* transform: translateX(-50%); */
  }

  .add-new-filter:hover {
    color: #f9f9f9;
    background-color: #225FB3;
    text-decoration: none;
    opacity: 1;
  }

  .filter-set-button {
    background-color: #225FB3;
    opacity: 0.6;
    color: #f9f9f9;
    padding: .3rem;
    border-radius: 100%;
    z-index: 2;
    cursor: pointer;
  }

  .filter-set-button:hover {
    color: #f9f9f9;
    background-color: #225FB3;
    text-decoration: none;
    opacity: 1;
  }

  .move-filter-button {
    border: none;
    font-weight: normal;
    font-size: inherit;
    color: #344777;
    /* width: 1.2rem;
    height: 1.2rem;
    min-width: 1.2rem;
    min-height: 1.2rem; */
    /* padding-right: .2rem; */
    padding: 0 0.3rem 0 0;
    border-radius: 100%;
    z-index: 2;
    cursor: pointer;
  }

  .move-filter-button:hover {
    color: #f9f9f9;
    background-color: #225FB3;
    text-decoration: none;
    opacity: 1;
  }

  .plus-icon-next-to-arrow {
    position: relative;
    font-size: .8rem;
    bottom: 2px;
    left: 2px;
  }

  .variant-table th::before {
    position: absolute;
    right: .5rem;
    top: 1rem;
    content: "";
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
  }
  
  .variant-table th.sort-asc::before {
    margin-top: 5px;
    border-bottom: 5px solid #22543d;
  }

  .variant-table th.sort-desc::before {
    margin-top: 5px;
    border-top: 5px solid #22543d;
  }

  .variant-table-resizer {
    display: inline-block;
    background:#aaa;
    width: 4px;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    transform: translateX(50%);
    z-index: 1;
    touch-action:none;
  }

  .variant-table-resizer:hover {
    -webkit-transition: all .2s ease;
    transition: all .2s ease;
    border-left: 3px solid #225FB3;
    border-right: 3px solid #225FB3;
  }

  .variant-table-resizer.isResizing {
    background: #225FB3;
    width: 3px;
  }

  .pagination {
    background-color: #f9f9f9;
    position: sticky;
    bottom: 0;
    left: 0;
    z-index: 1;
    padding: .3em .1em .1em .1em;
    margin: 0;
  }

  .igv-refresh-button {
    /* position: absolute;
    bottom: 0.5rem;
    left: 0.5rem; */
    /* top: 0; */
    /* right: 0; */
    color: #225FB3;
  }

  
  .genotype-value{
    display: block;
    width: 75%;
    overflow: hidden;
  }

  .genotype-quality::before {
    content: '*';
    position: absolute;
    top: -3px;
    left: -4px;
    /* right: 0px; */
    /* bottom: 0px; */
    /* font-size: 1.1em !important; */
  }

  /* .genotype-cell {
    position: relative;
    top: -10;
    bottom: -10;
    right: -10;
    left: -10;
    background-color: green;
  } */

  .genotype-cell {
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* position: relative; */
  }
  
  .genotype-cell:hover + .genotype-info {
    display: inline-block;
    /* opacity: 1;  */
  }

  /* .genotype-cell::after{
    content:'';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .genotype-cell.cross:after {
    background: linear-gradient(to left top, transparent calc(50% - 1px), gray 50%, transparent calc(50% + 1px) );
  } */


  .genotype-info {
    /* opacity: 0; */
    /* transition: opacity 0.3s ease-out;   */
    display: none;
    position: absolute;
    /* left: attr(data-left); */
    /* top: 0; */
    z-index: 1;
    margin: 4rem 0 0 2.5rem;
    padding: .2em .4em;
    border-radius: .4em;
    min-height: 2rem;
    border: .2em solid #344777;
    background-color: #ffffff;
    box-shadow: 0 .4em .5em rgba(0, 0, 0, .1), 0.4em .4em .5em rgba(0, 0, 0, .4);
  }

  .genotype-info::before {
    content: '';
    position: absolute;
    left: 0;
    top: 3%;
    width: 0;
    height: 0;
    /* border: .5em solid transparent;
    margin-left: -1.1em; */
    border: 0.625em solid transparent;
    border-left: 0;
    border-top: 0;
    border-right: 1.5em solid #344777;
    /* border-right-color: #344777; */
    /* margin-left: -0.625em; */
    margin-left: -1.6em;
  }

  /* .test-tag{
    background-color: #0052cc;
    position: absolute;
  }
  .test-tag::before {
    content: attr(data-content);
    position: absolute;
    background-color: #006000;
  } */
  
  .with-edge-indicator::before {
    content: '';
    position: relative;
    /* top: 0; */
    left: -5px;
    width: 3px;
    height: 85%;
    background-color:rgb(18,136,66);
    /* background-color:rgb(62,133,73); */
    /* background-color: #006000; */
    /* background-color: #225FB3; */
  }

   
/*
* ----------------------- case table  -----------------------
*/

  .case-table {
    width: 100%;
    /* border: 1px solid #344777; */
  }

  .case-table thead {
    /* display: flex; */
    position: sticky; 
    top: 0;
    z-index: 3;
  }
  
  .case-table th,
  .case-table td {
    flex-grow: 1;
    margin: 0;
    padding: 0.3rem 0.3rem !important;
    /* white-space: normal !important; */
    /* position: relative; */
  }
/* 
  .case-table th {
    padding: 0.4rem 0.4rem !important;
  } */

  .case-table th {
    /* padding: .5rem 0.2rem !important; */
    /* min-height: 4rem; */
    /* border-top: 1px solid #344777 !important; */
    border-bottom: 1px solid #344777 !important;
    /* background-color: #f9f9f9; */
    background-color: #486EB7;
    color: #ffffff;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .case-table th:hover {
    background-color: #344777;
  }
  
  .case-table td {
    display: flex;
    flex-direction: column;
    
    justify-content: center;
    align-items:center;
    /* vertical-align: middle; */
    /* display: table-cell; */
    min-height: 2.5rem; 
    /* margin: auto; */
    /* min-width: 8rem; */
    /* max-width: 50rem; */
    /* padding: .4rem .8rem !important; */
    /* overflow:hidden;
    white-space:nowrap;
    text-overflow:ellipsis; */
    /* word-wrap: break-word; */
  }


  

  /* .case-table tr:nth-child(even) { */
    /* background-color: #fafafa; */
  /* } */
  
  .case-table tbody > tr {
    position: relative;
    border-bottom: 1px solid #f8f8f8;
    /* border-bottom: 1px solid #ededed; */
    /* border-right: 1px solid #ededed; */

    /* filter: brightness(100%); */
    /* -webkit-filter: brightness(100%); */
    /* -webkit-filter: opacity(100%); */
    filter: saturate(1);
    -webkit-filter:saturate(1);
    -webkit-filter: drop-shadow();
    z-index: 1;
  }
  .case-table tbody > tr:hover {
    border-bottom:none;
    /* background-color:#B0C4DE !important; */
    /* background-color:#b9def0 !important; */
    /* filter: brightness(85%); */
    /* -webkit-filter: brightness(85%); */
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.5);
    filter: saturate(3);
    -webkit-filter:saturate(3);
    -webkit-transition: all .5s ease;
    -moz-transition: all .5s ease;
    -o-transition: all .5s ease;
    -ms-transition: all .5s ease;
    transition: all .5s ease;
    z-index: 2;
  }

  /* .row-edit-button {
    display: none;
    position: absolute;
    right: -2em;
    transition: .3s background-color;
    color: #126BC5;
    border-radius: 40%;
    padding: .4rem;
    margin: .4rem;
  }

  .row-edit-button:hover {
    background-color:#0A4386 !important;
    color: #ffffff;
    cursor: pointer;
  }

  .case-table tbody > tr:hover .row-edit-button {
    display: block;
  }  */

  
  .case-table th::before {
    position: absolute;
    right: .5rem;
    top: .1rem;
    content: "";
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
  }

  .case-table th.sort-asc::before {
    margin-top: 8px;
    border-bottom: 8px solid #ffff;
  }

  .case-table th.sort-desc::before {
    margin-top: 8px;
    border-top: 8px solid #ffff;
  }

  /* .case-table-save-row-info-panel {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    bottom: 0;
    right: -55px;
    padding-right: 0.6em;
    border-radius: 0 50% 50% 0;
  }

  .case-table-save-row-info-save-btn:hover {
    color: rgb(62,133,73) !important;
  }

  .case-table-save-row-info-cancel-btn:hover {
    color: #f33c1c !important;
  } */

  .primary-phenotype-cell {
    white-space:nowrap;
    overflow:hidden;
    text-overflow:ellipsis;
    width: 100%;
  }

  .case-note-cell {
    overflow:hidden;
    width: 100%;
    height: 100%;
  }

  .column-select-icon-panel {
    position: absolute;
    bottom: 0;
    left: 0;
    /* margin: 8px 4px; */
    /* opacity: .8; */
    /* cursor: pointer; */
    /* font-size:large; */
  }

  /* .column-select-icon-panel:hover {
    opacity: 1;
  }  */

  .column-select-icon {
    margin: .1em !important;
    padding: .0em .1em !important;
  }

  .use-or-for-search-icon-panel {
    position: absolute;
    top: 4px;
    left: 4px;
    z-index: 10;
    /* margin: 8px 4px; */
    /* opacity: .8; */
    /* cursor: pointer; */
    /* font-size:large; */
  }

  .add-new-case {
    position: fixed;
    /* border-color: rgba(0,0,0,0.2); */
    /* background-color: #ee6e73; */
    /* background-color: #789adb; */
    /* background-color: #6d90cf; */
    background-color: #225FB3;
    opacity: 0.6;
    color: #f9f9f9;
    width: 60px;
    height: 60px;
    bottom: 2.5rem;
    border-radius: 100%;
    z-index: 2;
    cursor: pointer;
    /* transform: translateX(-50%); */
  }
  .add-new-case:hover {
    color: #f9f9f9;
    background-color: #225FB3;
    text-decoration: none;
    opacity: 1;
  }

  .edit-btn {
    color: #ffffff;
  }

  .edit-btn:hover {
    color: #0052cc;
    background-color: #ffffff;
  }

  /* .analysis-list:hover {
    background-color:#b9def0 !important;
    cursor: pointer;
  } */

  .analysis-list {
    transition: .3s background-color;
    /* transition-delay:1s; */
  }

  .analysis-list:hover {
    background-color:#b9def0 !important;
    cursor: pointer;
  }

  .analysis-element {
    background-color: #e3eaf3;
    /* margin-bottom: 0rem; */
  }

  /* .analysis-element:nth-child(even) {
    background-color: #e3eaf3;
  } */

  /* .xxxanalysis-info-panel { */
    /* max-height: 0px;
    transition: max-height 1s ease-in;
    transition-delay: 0s;
    overflow:hidden; */
    /* height: 0px; */
    /* transition: 1s all; */
    /* transition: max-height 1s linear; */
    /* max-height: 500px; */
    /* transition: max-height 0.25s ease-in; */
    /*Transition time is increased to accommodate the height */
    /* transition:transform 0.3s ease-out; */
    /* note that we're transitioning transform, not height! */
    /* height:auto; */
    /* transform:scaleY(1);  */
    /* implicit, but good to specify explicitly */
    /* transform-origin:top;  */
    /* keep the top of the element in the same place. this is optional. */
  /* } */

  .analysis-info-panel {
    max-height: 80vh;
    height:auto;
    transition-delay: 0s;
    transition: max-height 1s ease-out;
  }

  .analysis-info-panel.collapsed {
    max-height: 0px;
    transition-delay: 0s;
    transition: max-height 1s linear;
    margin-bottom: 0rem;
  }

  /* .analysis-info-panel + .analysis-element{
    margin-bottom: 2rem;
  }
  .analysis-info-panel.collapsed + .analysis-element{
    margin-bottom: 0rem;
  } */

  /* .analysis-list:hover + .xxx{
    height: 20rem;
    background-color: #0052cc;
    max-height: 3000px;
  } */


  .analysis-edit-button {
    transition: .3s background-color;
    color: #126BC5;
    border-radius: 40%;
    padding: .4rem;
    margin: 0 0.3rem;
  }

  .analysis-edit-button:hover {
    background-color:#0A4386 !important;
    color: #ffffff;
    cursor: pointer;
  }

  .analysis-action-button-wrapper {
    position:absolute;
    right: 0.2rem;
    top: 0.3rem;
    /* transition: .3s background-color;
    color: #126BC5;
    border-radius: 5px;
    padding: .3rem; */
  }

  .analysis-action-button:hover {
    background-color:#0A4386 !important;
    /* color: #ffffff; */
    /* cursor: pointer; */
  }

  
  .add-new-analysis-wrapper {
    position: relative;
  }

  .add-new-analysis {
    position: relative;
    background-color: #225FB3;
    opacity: 0.6;
    color: #f9f9f9;
    width: 50px;
    height: 50px;
    border-radius: 100%;
    z-index: 2;
    cursor: pointer;
  }

  .add-new-analysis:hover {
    color: #f9f9f9;
    background-color: #225FB3;
    text-decoration: none;
    opacity: 1;
  }



/*
* ----------------------- loading progress bar -----------------------
*/

  .data-loading-panel {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
    height: 100%;
    width: 100%;
    z-index: 100;
    background-color: rgba(255, 255, 255, 0.8);
  }

  .loading-message {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80%;
    padding-bottom: 15vh;
  }
  .progress {
    width: 100%;
    max-width: 400px;
  }
  .progress-bar {
    max-width: 400px;
  }





/*
 * xxxxxx
 */


  .note-bubble {
    position: relative;
    /* background: #dcf3ff; */
    /* background: #b1ccfb; */
    /* background: #a9a6f5; */
    /* background: #edb485; */
    /* background: #ffbdd4; */
    background: #b9def0;
    /* background: #225FB3; */
    /* background: #ededed; */
    /* background: #ffffff; */
    border-radius: .4em;
    min-height: 2rem;
    /* min-width: 10rem; */
    margin-bottom: .4rem;
    margin-left: .3rem;
    padding: .1rem .4rem .3rem .4rem;
  }

  .note-bubble::after {
    content: '';
    position: absolute;
    left: 0;
    top: 70%;
    width: 0;
    height: 0;
    border: 0.625em solid transparent;
    /* border-right-color: #dcf3ff; */
    border-right-color: #b9def0;
    /* border-right-color: #225FB3; */
    /* border-right-color: #ededed; */
    /* border-right-color: #ffffff; */
    border-left: 0;
    border-top: 0;
    margin-top: -0.312em;
    margin-left: -0.625em;
  }

  .aggnote-bubble {
    /* background: #94bbff; */
    /* background: #a9a6f5; */
    background: #eabe9a;
    /* background: #ffbdd4; */
  }

  .aggnote-bubble::after {
    /* border-right-color: #94bbff; */
    /* border-right-color: #a9a6f5; */
    border-right-color: #eabe9a;
    /* border-right-color: #ffbdd4; */
  }

  .note-div {
    background-color: #f9f9f9;
    /* border: 1px solid black; */
    width: 100%;
    word-break: break-word;
  }

  /*
    background: #ecba92;
    background: #b1ccfb;
    background: #a9a6f5;
    background: #f8a765;
    background: #ffbdd4;

  */

  .border-shadow1 {
    box-shadow: 0 0 5px 5px #ef7714;  
  }

.btn-outline-project:hover, .btn-outline-project:focus, .btn-outline-project:active {
  border-color: #00b3db;
  /* background-color: transparent; */
  /* border-bottom-color: #00b3db; */
}



.react-autosuggest__container {
  position: relative;
  /* z-index: 1010; */
}

.react-autosuggest__input {
  /* width: 240px; */
  /* height: 30px;
  padding: 10px 20px; */
  /* font-family: Helvetica, sans-serif; */
  /* font-weight: 300; */
  /* font-size: 16px; */
  border: 1px solid #aaa;
  border-radius: 4px;

  padding: 0.2rem .5rem;
  width: fit-content;
  min-width: 19rem;
}

.react-autosuggest__input--focused {
  outline: none;
}

.react-autosuggest__input--open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
  display: none;
}

.react-autosuggest__suggestions-container--open {
  display: block;
  position: absolute;
  /* top: 5px; */
  width: 300px;
  border: 1px solid #aaa;
  background-color: #fff;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 16px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  max-height: 300px;
  overflow-y: auto;
  z-index: 1012;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 10px 20px;
}

.react-autosuggest__suggestion--highlighted {
  background-color: #ddd;
}

@keyframes glow {
  0% {
    stroke-opacity: 1;
    stroke-width: 2px;
    fill-opacity: 0.5;
  }
  50% {
    stroke-opacity: 0.5;
    stroke-width: 10px;
    fill-opacity: 0.1;
  }
  100% {
    stroke-opacity: 1;
    stroke-width: 2px;
    fill-opacity: 0.5;
  }
}
/* 
.svgStyle {
  width: 200px;
  height: 200px;
  display: block;
  margin: auto;
  background: black;
} */

.circleStyle {
  fill: rgba(255, 255, 0, 0.5); /* Semi-transparent yellow */
  stroke: yellow;
  stroke-width: 2px;
  animation: glow 2s infinite ease-in-out;
}

.accordion-button {
  background: #eabe9a;
  padding:5px;
}

.accordion-body {
  padding:5px;
  background: #eabe9a;
}

.accordion-item {
  border: none;
}

.accordion-button:not(.collapsed) {
  color: #212529; 
  background: #eabe9a; 
  box-shadow: none; 
}

.accordion-button:focus {
  box-shadow: none;
}


/* Removes the arrow and the space for the arrow */
/*
 .accordion-button::after {
  background-image: initial;
  width: 0;
}

.accordion-button:not(.collapsed)::after {
  background-image: initial;
  width: 0;
} */

.ClinGen-Item{
  flex: 1 1;
  padding: .6rem .6rem .6rem .6rem;  
}

/*   .variant-item {
    min-width: 7rem;
    padding: 0rem .6rem;
    flex: 1 1;
  } */

  .AggNote-divider {
    display: flex;
    flex-direction: column; /* Stacks the text and line vertically */
    align-items: center; /* Centers the text horizontally */
    justify-content: center; /* Ensures everything is centered */
    margin: 10px 0; /* Adjusts top and bottom spacing */
  }
  
  .AggNote-divider span {
    background-color: #fff; /* Background color of the text area */
    padding: 0 10px; /* Adds some padding around the text */
    font-weight: bold;
    font-size: 14px;
    color: #ef7714; /* Text color (example: orange) */
    z-index: 1; /* Ensures text is on top of the line */
  }

  .AggNote-divider::after {
    content: ''; /* Creates an empty content for the divider line */
    width: 100%; /* Makes the line take up the full width */
    height: 1px; /* Sets the thickness of the line */
    background-color: #ef7714; /* Divider line color */
    margin-top: 2px; /* Adds some space between the text and line */
    margin-bottom: 2px; /* Adds some space between the text and line */
    z-index: 0; /* Makes sure the line is below the text */
  }

  /* .icon-with-outline {
    border-radius: 30%; 
    padding: 2px;
    background-color: #ffffff; 
  } */